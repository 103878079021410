<template>
  <v-container>
    <h1 v-html="$t('issues_title')"></h1>
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading" content-class="loading">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <div>
      <v-row class="row search spacer-md">
        <v-col xs="12" md="6" xl="4">
          <search-application-user ref="search-application-user" @search="search" />
        </v-col>
        <v-col xs="12" md="6" xl="4">
          <search-issues ref="search-issues" @search="search" />
        </v-col>
      </v-row>
      <v-btn color="primary" @click="search"><span v-html="$t('search_issue_search')" /></v-btn>
      <v-btn color="secondary" @click="reset"><span v-html="$t('search_issue_reset')" /></v-btn>
      <v-btn color="secondary" @click="createNewGeneralIssue"><span v-html="$t('search_issue_new_general_issue')" /></v-btn>
      <v-btn color="secondary" :loading="loadingDownload" @click="download"><span v-html="$t('download')" /></v-btn>
      <v-data-table-server
        :items="items"
        :headers="fields"
        :loading="loading"
        :items-length="totalElements"
        :multi-sort="true"
        @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(query.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
        :items-per-page-options="$itemsPerPageOptions"
        density="compact"
      >
        <template v-slot:[`item.edit`]="{ item }">
          <a :href="resolveHrefForIssueEdit(item.id)" target="_blank" class="tableaction link">
            <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
          </a>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <template v-if="item.type">
            {{ showIssueType(item.type) }}
          </template>
        </template>
        <template v-slot:[`item.notifiedEmails`]="{ item }">
          {{ showRecipient(item.notifiedEmails) }}
        </template>
        <template v-slot:[`item.displayLink`]="{ item }">
          <div v-if="item.displayLink">
            <span @click="goToLink(item)" class="link-navigation">
              <v-icon dbmblueprimary size="12px">mdi-chevron-right</v-icon>
            </span>
          </div>
        </template>
        <template v-slot:[`item.dueDate`]="{ item }">
          <template v-if="item.dueDate">
            {{ item.dueDate ? $formatChDate(item.dueDate) : '' }}
          </template>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-switch :loading="item.loading" @change="patchDone(item)" id="issue_item_done" v-model="item.statusBoolean" color="primary"></v-switch>
        </template>
      </v-data-table-server>
    </div>
  </v-container>
</template>
<script scoped lang="ts">
import { apiURL } from '../../main'
import { Term } from '../../services/term'
import { fileCreator, showError } from '@/services/axios'
import searchApplicationUser from '@/components/searchCards/searchApplicationUser.vue'
import { useSearchApplicationUserStore } from '@/store/SearchApplicationUserStore'
let searchApplicationUserStore = useSearchApplicationUserStore()
import { useSearchIssuesStore } from '@/store/SearchIssuesStore'
let searchIssuesStore = useSearchIssuesStore()
import searchIssues from '@/components/searchCards/searchIssues.vue'
import { DTSHeaders } from '@/services/BackendService'
import { useIssueTypesStore } from '@/store/enums/IssueTypesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'issues_read',
  data() {
    return {
      items: [],
      loading: false,
      loadingDownload: false,
      fields: <DTSHeaders>[
        // Edit
        {
          title: '',
          key: 'edit',
          sortable: false
        },
        // Typ
        {
          title: this.$t('issues_table_header_type'),
          key: 'type',
          sortable: true,
          align: 'left'
        },
        // Betreff
        {
          title: this.$t('issues_table_header_regarding'),
          key: 'subject' + this.$getUpLang(),
          sortable: true
        },
        // Empfänger
        {
          title: this.$t('issues_table_header_recipient'),
          key: 'notifiedEmails',
          sortable: true
        },
        // Link
        {
          title: this.$t('issues_table_header_link'),
          key: 'displayLink',
          sortable: true
        },
        // Fälligkeitsdatum
        {
          title: this.$t('issues_table_header_due_datum'),
          key: 'dueDate',
          sortable: true
        },
        // Erledigt
        {
          title: this.$t('issues_table_header_done'),
          key: 'status',
          sortable: true
        }
      ],
      totalElements: 0
    }
  },
  components: {
    searchApplicationUser,
    searchIssues
  },
  computed: {
    issueTypes() {
      return useIssueTypesStore().issueTypes
    },
    term(): any {
      return [...Term.buildTermItems(searchApplicationUserStore, searchApplicationUserStore.items)]
    },
    query(): any {
      return {
        ...this.getJavaPageOptions({ sort: ['status,asc', 'dueDate,asc', 'type,asc'] }),
        term: Term.stringify(this.term),
        issueType: searchIssuesStore.issues_type,
        issueStatus: searchIssuesStore.issues_status,
        subject: searchIssuesStore.issues_subject,
        legalEntityIdSender: searchIssuesStore.issues_sender_id,
        legalEntityIdRecipient: searchIssuesStore.issues_receiver_id,
        responsibleUserId: searchIssuesStore.issues_assigned_person,
        applicationUserAgateId: searchApplicationUserStore.ApplicationUser_agateId,
        applicationRoleId: searchApplicationUserStore.ApplicationUser_applicationRoleId,
        siblingGuid: this.$route.params.siblingGuid,
        acceptLanguage: 'de'
      }
    }
  },
  methods: {
    showRecipient(notifiedEmails) {
      var recipient = ''
      if (notifiedEmails) {
        notifiedEmails.forEach((element) => {
          recipient += ' ' + element
        })
      }
      return recipient
    },
    showIssueType(type) {
      let issueType = this.issueTypes.filter((el) => el.name === type)[0]
      return issueType[this.$getLangKey()]
    },
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      try {
        this.loading = true
        const response = await this.axios.get(apiURL + '/issues', {
          params: this.query
        })
        const items = response.data.content
        this.items = Array.isArray(items) ? items : []
        this.items = this.items.map((item) => {
          if (item.status === 'DONE') item.statusBoolean = true
          else item.statusBoolean = false
          return item
        })
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (error) {
        console.log('error', error)
        showError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-application-user'].reset()
      this.$refs['search-issues'].reset()
    },
    createNewGeneralIssue() {
      this.$router.push({ name: 'issues_create' }).catch(() => {
        /* duplicated route */
      })
      // this.$router.push({ name: 'general_issue_create' }).catch((e) => {
      //   /* duplicated route */
      // })
    },
    async download() {
      try {
        this.loadingDownload = true
        const response = await this.axios.get(apiURL + '/issues', {
          params: this.query,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'Issues_Report.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingDownload = false
      }
    },
    async patchDone(item) {
      const status = item.status === 'DONE' ? (item.status = 'TO_DO') : (item.status = 'DONE')
      try {
        const result = await this.axios.patch(
          apiURL + `/issues/${item.id}`,
          {
            status: status
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
      } catch (e) {
        showError(e)
      }
    },
    goToLink(item) {
      this.$router
        .push({
          path: '/' + this.$route.params.lang + '/' + item.contextLink
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    resolveHrefForIssueEdit(id) {
      return this.$router.resolve({ name: 'issues_edit', params: { id } }).href
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  beforeMount() {
    useIssueTypesStore().fetchIssueTypes()
  },
  mounted() {
    if (this.$route.query.size) this.load()
  }
})
</script>
